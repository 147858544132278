#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 1px solid #7A956C;
  border-top-color: #B1CDA2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}