#loader {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
}

#loader:after {
  content: "";
  border: 1px solid #7a956c;
  border-top-color: #b1cda2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.b7dcbedb.css.map */
